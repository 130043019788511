














































































import { Component, Vue } from 'vue-property-decorator';
import { MaterialsContent } from '@/types/neuhaus-website/cms/pages/materials'
import axios, { APIResponse } from '@/plugins/axios';

/**
 * CMSPageMaterials Component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'cms-page': () => import('@/components/cms/pages/CMSPage.vue'),
        'cms-component-expansion-panel': () => import('@/components/cms/components/CMSComponentExpansionPanel.vue'),
        'cms-component-image': () => import('@/components/cms/components/CMSComponentImage.vue')
    }
})
export default class CMSPageMaterials extends Vue {
    // variable with page content
    private pageContent: MaterialsContent = {
        id: -1,
        title: 'N/A',
        expansionPanels: [],
        rightEdgeImage: {
            id: -1,
            src: '',
            obj: undefined
        },
        bottomImage: {
            id: -1,
            src: '',
            obj: undefined
        }
    }

    /**
     * @returns Returns API URL + given url
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private getAbsoluteImageUrl(url: string): string {
        return process.env.VUE_APP_API_URL + url;
    }

    /**
     * Function will be executed on component load
     * fetches page content
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        this.pageContent = await this.fetchPageContent();
    }

    /**
     * Fetches page content from API
     * 
     * @returns MaterialsContent
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchPageContent(): Promise<MaterialsContent> {
        try {
            const response = await axios.get<APIResponse<MaterialsContent>>('/cms/pages/materials')

            if (response.data.status === 'error' || !response.data.data) {
                throw null;
            }

            return response.data.data;
        } catch (err) {
            return this.pageContent;
        }
    }
}
